// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-company-jsx": () => import("./../../../src/pages/about-company.jsx" /* webpackChunkName: "component---src-pages-about-company-jsx" */),
  "component---src-pages-ampm-jsx": () => import("./../../../src/pages/ampm.jsx" /* webpackChunkName: "component---src-pages-ampm-jsx" */),
  "component---src-pages-brands-jsx": () => import("./../../../src/pages/brands.jsx" /* webpackChunkName: "component---src-pages-brands-jsx" */),
  "component---src-pages-career-jsx": () => import("./../../../src/pages/career.jsx" /* webpackChunkName: "component---src-pages-career-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-damixa-jsx": () => import("./../../../src/pages/damixa.jsx" /* webpackChunkName: "component---src-pages-damixa-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-policy-jsx": () => import("./../../../src/pages/policy.jsx" /* webpackChunkName: "component---src-pages-policy-jsx" */),
  "component---src-pages-reviews-jsx": () => import("./../../../src/pages/reviews.jsx" /* webpackChunkName: "component---src-pages-reviews-jsx" */),
  "component---src-pages-wishbox-jsx": () => import("./../../../src/pages/wishbox.jsx" /* webpackChunkName: "component---src-pages-wishbox-jsx" */)
}

