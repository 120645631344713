import React from "react"
import {withTrans} from '../../i18n/withTrans';

const Layout = ({children, t, i18n}) => {
    return (
        <main>{children}</main>
    )
}

export default withTrans(Layout)
